<script setup>
import Layout from "../../layouts/main.vue";

import { profileUserStore } from "../../stores/profileUser";
const profile_user = profileUserStore();
</script>

<template>
  <Layout>
    <div class="position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg profile-setting-img">
        <img
          src="../../assets/images/login-background.jpg"
          class="profile-wid-img"
          alt=""
        />
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-3">
        <div class="card mt-n5">
          <div class="card-body p-4">
            <div class="text-center">
              <div
                class="profile-user position-relative d-inline-block mx-auto mb-4"
              >
                <img
                  v-if="profile_user.avatarLink"
                  :src="profile_user.avatarLink"
                  class="rounded-circle avatar-xl img-thumbnail user-profile-image"
                  alt="user-profile-image"
                />
                <img
                  v-else
                  src="../../assets/images/users/user-dummy-img.jpg"
                  class="rounded-circle avatar-xl img-thumbnail user-profile-image"
                  alt="user-profile-image"
                />
              </div>
              <h5 class="fs-16 mb-1">{{ profile_user.name }}</h5>
              <p class="text-muted mb-0">
                {{
                  profile_user.roles === "User"
                    ? "View"
                    : profile_user.roles === "AdminPrimary"
                    ? "Admin"
                    : profile_user.roles === "Admin"
                    ? "Super Admin"
                    : profile_user.roles
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-9">
        <div class="card mt-xxl-n5" style="font-size: 16px">
          <div class="card-header text-uppercase">
            {{ $t("t_account_detail") }}
          </div>
          <div class="card-body p-4">
            <p>
              <b>{{ $t("t-full-name") }}: </b> {{ profile_user.name }}
            </p>
            <p>
              <b>{{ $t("t-account-roles") }}: </b
              >{{
                profile_user.roles === "User"
                  ? "View"
                  : profile_user.roles === "AdminPrimary"
                  ? "Admin"
                  : profile_user.roles === "Admin"
                  ? "Super Admin"
                  : profile_user.roles
              }}
            </p>
            <p>
              <b>{{ $t("t_department") }}: </b> {{ profile_user.department }}
            </p>
            <p>
              <b>{{ $t("t_phone_number") }}: </b> {{ profile_user.phone }}
            </p>
            <p>
              <b>{{ $t("t_email") }}: </b> {{ profile_user.email }}
            </p>
            <div>
              <b>{{ $t("t-group-roles") }}: </b>
              <b-row>
                <b-col md="3" v-for="item of profile_user.group" :key="item.id">
                  <span class="badge rounded-pill badge-soft-info m-2">{{
                    item
                  }}</span>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.profile-setting-img {
  height: 125px;
}
span {
  margin-right: 5px;
  font-size: 15px;
}
</style>
